<template>
    <auth-layout :title="$t('login.title')" :button-text="$t('login.button')" :action="submit">
        <validation-provider name="email" rules="required" v-slot="{ errors, reset }" slim>
            <input-group :errors="errors" :label="$t('validation.names.email')" required>
                <input-component
                    v-model="form.email"
                    type="email"
                    :placeholder="$t('validation.names.email')"
                    @input="reset"
                    @mousedown.stop
                    autocomplete
                />
            </input-group>
        </validation-provider>

        <validation-provider name="password" rules="required" v-slot="{ errors, reset }" slim>
            <input-group :errors="errors" :label="$t('validation.names.password')" required>
                <input-component
                    v-model="form.password"
                    type="password"
                    autocomplete="current-password"
                    :placeholder="$t('validation.names.password')"
                    @input="reset"
                    @mousedown.stop
                />
            </input-group>
        </validation-provider>

        <validation-provider name="two_factor_auth_code" rules="digits:6" v-slot="{ errors, reset }" slim>
            <input-group :errors="errors" :label="$t('validation.names.two_factor_auth_code')">
                <input-component v-model="form.codeTfa" :placeholder="$t('validation.names.two_factor_auth_code')" @input="reset" @mousedown.stop />
            </input-group>
        </validation-provider>

        <validation-provider name="two_factor_recovery_code" rules="recovery_code" v-slot="{ errors, reset }" slim>
            <input-group :errors="errors" :label="$t('validation.names.two_factor_recovery_code')">
                <input-component
                    v-model="form.recoveryCodeTfa"
                    :placeholder="$t('validation.names.two_factor_recovery_code')"
                    @input="reset"
                    @mousedown.stop
                />
            </input-group>
        </validation-provider>

        <template #links>
            <router-link :to="{ name: 'application' }">{{ $t('login.apply') }}</router-link>

            <router-link :to="{ name: 'forgot-password' }">{{ $t('login.forgot_password') }}</router-link>
        </template>
    </auth-layout>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';
    import { changeLocale, login } from '@/services/skinsPaymentApi';
    import { SET_TOKEN, SET_USER } from '@/store/mutations';
    import AuthLayout from '@/pages/layout/AuthLayout';
    import InputComponent from '@/components/inputs/InputComponent';
    import InputGroup from '@/components/inputs/InputGroup';

    export default {
        name: 'Login',
        components: {
            AuthLayout,
            InputComponent,
            InputGroup,
            ValidationProvider,
        },
        props: {
            successMessage: String,
        },
        methods: {
            submit(recaptchaToken) {
                return login(this.form.email, this.form.password, recaptchaToken, this.form.codeTfa, this.form.recoveryCodeTfa).then(response => {
                    if (this.$i18n.locale !== response.data.locale) {
                        changeLocale(this.$i18n.locale);
                    }

                    this.$store.commit(`user/${SET_USER}`, { ...response.data, locale: this.$i18n.locale });
                    this.$store.commit(`auth/${SET_TOKEN}`, response.headers.authorization);
                    this.$router.push({ name: response.data['2fa_required'] ? 'tfa' : 'account' });
                });
            },
        },
        data() {
            return {
                form: {
                    email: null,
                    password: null,
                    codeTfa: null,
                    recoveryCodeTfa: null,
                },
            };
        },
        mounted() {
            if (this.successMessage) {
                this.$toast.success(this.successMessage);
            }
        },
    };
</script>
